* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-sizing: border-box;
    background: transparent;
    font-family: inherit;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none;
}
a img {
    border: none;
}
  
button,
a {
    cursor: pointer;
    color: inherit;
}

textarea {
    resize: none;
}
  
button,
textarea,
select {
    appearance: none !important;
    border-radius: 0;
}

/* END OF CSS RESET */

/* COLOR PALETTE */

:root{
    --primaryColor: ;
    --darkgray: #011627;
    --purple: #480ca8;
    --lpurple: #640ab2;
    --white: #e6e6ee;
    --orange: #E39E18;
    --lgray: #c1bdb3;
    --midgray: #131517;
    --midgrayop: #1315176c;
    --red: #ef233c;
}

/* END OF COLOR PALETTE */


/* FONT CSS PROPERTIES */

h2{
    color: var(--white);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.02em;
}

h4{
    font-weight: 500;
}

a, p{
    color: var(--white);
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5em;
}

p.header-text{
    margin: 20px 0px;
}

.packageType{
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5em;
}

/* END OF FONT CSS PROPERTIES */


/* ICON CSS PROPERTIES */

@import url('https://css.gg/css?=|close');

.gg-close {
    transform: scale(var(--ggs,1.2));
}

/* END OF ICON CSS PROPERTIES */


/* BUTTON CSS PROPERTIES */

.default-btn, .default-form-btn{
    background-color: var(--orange);
    color: var(--midgray);
    min-width: 150px;
    max-width: fit-content;
    margin: 20px 0px;
    padding: 10px 25px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
}

.close-btn{
    background-color: var(--red);
    color: var(--white);
    width: 40px;
    height: 40px;
    padding: 2px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-btn {
    list-style-type: none;
    margin: 5px 0px;
}

.radio-btn li {
    min-width: 150px;
    max-width: fit-content;
    height: 40px;
    margin: 0 5px 0 0;
    position: relative;
    display: inline-block;
}
  
.radio-btn label,
.radio-btn input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
  
.radio-btn input[type="radio"] {
    opacity: 0.01;
    z-index: 100;
}
  
.radio-btn input[type="radio"]:checked+label,
.Checked+label {
background: var(--lpurple);

}
  
.radio-btn label {
    background-color: rgba(100, 10, 178, 0.2);
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    z-index: 90;
    text-align: center;
    line-height: 1.5em;
}
  
.radio-btn label:hover {
    background: var(--lpurple);
}

input[type="text"].popup-btn {
    background-color: var(--red);
    color: var(--white);
    min-width: 150px;
    max-width: fit-content;
    margin-right: 10px;
    padding: 10px 10px;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    text-align: center;
}

/* END OF BUTTON CSS PROPERTIES */


/* IMAGES CSS PROPERTIES */

.column img{
    width: 100%;
    margin: 10px 0px;
    border-radius: 10px;
} 

/* END OF IMAGES CSS PROPERTIEs */


/* INPUT CSS PROPERTIES */

input[type=text], [type=email], [type=tel], [type=date], [type=number]{
    color: var(--white);
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    border: 2px solid var(--white);
    border-radius: 10px;
    font-size: 1rem;
}

input[type=radio]{
    margin: 10px 10px;
    padding: 10px;
    border: 2px solid var(--white);
    border-radius: 10px;
    font-size: 1rem;
}

/* END OF INPUT CSS PROPERTIES */


/* LABEL CSS PROPERTIES */

label{
    color: var(--white);
    font-size: 1rem;
}

/* END OF LABEL CSS PROPERTIES */

/* ROOT CSS PROPERTIES */

#root{
    background-color: var(--midgray);
}

/* END OF ROOT CSS PROPERTIES */


/* WRAPPER CSS PROPERTIES */

.wrapper{
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
}

.two-column-wrapper{
    max-width: 1140px;
    width: 95%;
    margin: 0 auto;
    padding: 2em 0;

}

.optimal-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer-wrapper{
    max-width: 1140px;
    width: 95%;
    margin: 0 auto;

}

.header-wrapper{
    max-width: 1140px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    padding: 2em 0;
}
.insurance-wrapper{
    max-width: 1140px;
    width: 95%;
    margin: 0 auto;
}

@media (min-width: 40em) {
    .two-column-wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 2rem 0;
    }
    .table-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .more-wrapper{
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 2rem 0;
    }
    .footer-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2rem 0;
    }
    .header-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 5rem 0;
    }
    .insurance-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .landing-swiper-container{
        width: 500px;
    }
    .insurance-card{
        min-width: 250px;
        max-width: fit-content;
    }

}


.column{
    width: 100%;
    padding: 10px;
}




/* END OF WRAPPER CSS PROPERTIES */


/* NAVIGATION CSS PROPERTIES */

.navigation{
    background-color: var(--lpurple);
    color: var(--midgray);
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation-wrapper{
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* END OF NAVIGATION CSS PROPERTIES */


/* HEADER CSS PROPERTIES */

header{
    background-image: url('./images/esernyo.png');
    background-position: center;
    background-size: cover;
}

/* END OF HEADER CSS PROPERTIES */


/* SWIPER CSS PROPERTIES */

#root .swiper-pagination {
    height: 50px;
    bottom: -32px;
}

/* END OF SWIPER CSS PROPERTIES */


/* FOOTER CSS PROPERTIES */

.footer{
    background-color: var(--purple);
    color: var(--midgray);
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
}

.footer-info{
    background-color: var(--purple);
    color: var(--midgray);
    width: 100%;
    height: fit-content;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* END OF FOOTER CSS PROPERTIES */


/* CARD CSS PROPERTIES */

.card{
    background-color: var(--lpurple);
    color: var(--white);
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
    padding: 10px;
    border: 2px solid var(--white);
    border-radius: 10px;
}

.insurance-card{
    background-color: var(--lpurple);
    color: var(--white);
    width: 95%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: 15px 10px;
    padding: 10px;
    border-radius: 10px;
}


/* END OF CARD CSS PROPERTIES */


/* TABLE CSS PROPERTIES */

.table-grid{
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-buttons{
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 500px) {
    .table-buttons {
        width: 100%;
        flex-direction: column;
    }
}

.table-button{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

table{
    border-spacing: 0pt;
    border-collapse: separate;
}

th, td {
    color: white;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--white);
    border-spacing: 0pt;
    border-collapse: separate;
}

tr{
    display: table-row;
}

th{
    background-color: var(--lpurple);
}

.table-text{
    text-align: left;
    width: 50%;
}

.table-price{
    text-align: center;
    width: 50%;
}

table tr:first-child th:first-child{
  border-top-left-radius: 10px;
}

table tr:first-child th:last-child{
  border-top-right-radius: 10px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}


/* END OF TABLE CSS PROPERTIES */

/* POPUP CSS PROPERTIES */

.popup-overlay{
    background-color: rgba(0, 0, 0, 0.950);
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    z-index: 10;
}

.popup-wrapper{
    background-color: var(--midgray);
    max-width: 750px;
    width: 90%;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 11;
}



.popup-info{
    display: flex;
}

/* END OF POPUP CSS PROPERTIES */


/* OTHER CSS PROPERTIES */

.additional-info{
    background-color: var(--lpurple);
    color: var(--white);
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    letter-spacing: 0.02em;
}

@media (max-height: 850px){
    .popup-wrapper{
        max-height: 95vh;
    }
    .popup h2{
        font-size: 1.2rem;
    }
    .popup label{
        display: none;
    }
    .close-btn{
        width: 30px;
        height: 30px;
    }
    .default-btn{
        margin: 5px 0px;
    }
    input[type="text"].popup-btn {
        background-color: var(--red);
        color: var(--white);
        min-width: 100px;
        max-width: fit-content;
        padding: 5px;
        border: none;
        border-radius: 10px;
        font-size: 1rem;
        text-align: center;
    }

}

input[type='date'], input[type='time'] {
    min-height: 45px;
}

/* END OF OTHER CSS PROPERTIES */


/* COOKIE CONSENT CSS PROPERTIES */

.cookie-bar{
    background-color: var(--lpurple);
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
}

.cookie-wrapper{
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

/* END OF COOKIE CONSENT CSS PROPERTIES */
